import axios from "axios";
import camelize from "camelize";
import { getAdminApiUri } from "./NetworkService";

export const getCountriesRequest = async () => {
  const uri = getAdminApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/countries/` })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "succes";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération des pays";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Erreur lors de la récupération des pays";
    });

  return responseData;
};

export const getSynodesRequest = async ( countryId ) => {
  const uri = getAdminApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/countries/${countryId}/synodes/` })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération des synodes";
      }
    }).catch((error) => {
      responseData.status = "error";
    });

  return responseData;
};

export const getCitiesRequest = async ( countryId, synodeId ) => {
  const uri = getAdminApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/countries/${countryId}/synodes/${synodeId}/cities/` })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération des villes";
      }
    }).catch((error) => {
      responseData.status = "error";
    });

  return responseData;
};

export const getChurchesRequest = async () => {
  const uri = getAdminApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/churches/` })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération des églises";
      }
    }).catch((error) => {
      responseData.status = "error";
    });

  return responseData;
};

export const getChurchesLocationsRequest = async ( countryId, synodeId, cityId, churchId ) => {
  const uri = getAdminApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/countries/${countryId}/synodes/${synodeId}/cities/${cityId}/churches/${churchId}/locations/` })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération des lieux de l'église";
      }
    }).catch((error) => {
      responseData.status = "error";
    });

  return responseData;
};

export const getDepartmentsRequest = async ( churchId ) => {
  const uri = getAdminApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/churches/${churchId}/departments/` })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération des départements";
      }
    }).catch((error) => {
      responseData.status = "error";
    });

  return responseData;
};
