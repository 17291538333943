import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";
import LoginPage from "./pages/authentications/LoginPage";
import RegisterPage from "./pages/authentications/RegisterPage";
import HomePage from "./pages/generals/HomePage";
import PresencePage from "./pages/generals/PresencePage";
import CalendarPage from "./pages/generals/CalendarPage";
import CellPage from "./pages/cells/CellPage";
import CellAddPage from "./pages/cells/CellAddPage";
import CellReportingPage from "./pages/cells/CellReportingPage";
import CellReportingAddPage from "./pages/cells/CellReportingAddPage";
import CellDetailPage from "./pages/cells/CellDetailPage";
import CellEditPage from "./pages/cells/CellEditPage";

const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login/" element={<LoginPage />} />
        <Route path="/signup/" element={<RegisterPage />} />
        <Route path="/home/" element={<HomePage />} />
        <Route path="/presences/" element={<PresencePage />} />
        <Route path="/calendar/" element={<CalendarPage />} />
        <Route path="/cells/" element={<CellPage />} />
        <Route path="/cells/add/" element={<CellAddPage />} />
        <Route path="/cells/detail/:cellId/" element={<CellDetailPage />} />
        <Route path="/cells/edit/:cellId/" element={<CellEditPage />} />
        <Route path="/cells/reportings/" element={<CellReportingPage />} />
        <Route path="/cells/reportings/add/" element={<CellReportingAddPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
