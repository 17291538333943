import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { IoAddSharp } from "react-icons/io5";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { isEmpty } from "../../utils";

const CellReportingAddPage = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [offeringTotal, setOfferingTotal] = useState(0);

  useEffect(() => {
    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    navigate("/cells/", {
      state: {
        status: "success",
        message: "Rapport de cellule créée avec succès",
      }
    });
  }

  return (
    <div className="CellReportingPage">
      <ToastContainer />
      <Header />
      <Sidebar currentPage="cellReportings" />

      <div className="lg:pl-80 pt-14">
        <div className="p-8">
          <div className="w-full h-full space-y-8">
            <div className="space-y-4">
              <h1 className="text-center text-3xl font-semibold">Ajouter un rapport de cellule</h1>
              <p className="text-center text-sm">Écrivez votre rapport en remplissant les informations suivantes</p>
            </div>
            <form className="flex flex-col space-y-10 sm:mx-16" onSubmit={(e) => handleFormSubmit(e)}>
              <div className="space-y-12">
                <div className="bg-secondary p-8 sm:p-12 rounded-3xl shadow-lg">
                  <h2 className="text-center text-2xl font-semibold mb-8">Informations sur le temps</h2>
                  <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="cell">Cellule</label>
                      <select id="cell" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700">
                        <option value="1">Cellule 1</option>
                        <option value="2">Cellule 2</option>
                      </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="date">Date</label>
                      <input id="date" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="date" name="date" placeholder="Date de la cellule" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="hourStart">Heure de début</label>
                      <input id="hourStart" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="hourStart" placeholder="08h50" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="hourEnd">Heure de fin</label>
                      <input id="hourEnd" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="hourEnd" placeholder="09h45" />
                    </div>
                  </div>
                </div>
                <div className="bg-secondary p-8 sm:p-12 rounded-3xl shadow-lg">
                  <h2 className="text-center text-2xl font-semibold mb-8">Informations sur les participants</h2>
                  <div className="grid grid-cols-1 xl:grid-cols-3 gap-6">
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="adult">Adulte</label>
                      <input id="adult" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="number" name="adult" placeholder="Nombre d'adulte(s)" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="child">Enfant</label>
                      <input id="child" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="number" name="child" placeholder="Nombre d'enfant(s)" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="new">Nouveau</label>
                      <input id="new" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="number" name="new" placeholder="Nombre de nouvelle(s) personne(s)" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="nameParticipant1">Nom participant 1</label>
                      <input id="nameParticipant1" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="nameParticipant1" placeholder="Nom et prénom(s)" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="phoneParticipant1">Téléphone participant 1</label>
                      <input id="phoneParticipant1" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="phoneParticipant1" placeholder="Numéro de téléphone" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="departmentParticipant1">Département participant 1</label>
                      <select id="departmentParticipant1" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700">
                        <option value="">-</option>
                        <option value="1">Tanora</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="bg-secondary p-8 sm:p-12 rounded-3xl shadow-lg">
                  <h2 className="text-center text-2xl font-semibold mb-8">Informations sur l'organisation</h2>
                  <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="spiritualPreparation">Fanomanam-panahy</label>
                      <input id="spiritualPreparation" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="spiritualPreparation" placeholder="Rl Christian" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="spiritualPreparationDuration">Durée fanomanam-panahy</label>
                      <input id="spiritualPreparationDuration" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="number" name="spiritualPreparationDuration" placeholder="5 mn" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="praise">Fiderana</label>
                      <input id="praise" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="praise" placeholder="Rv Sabrina" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="praiseDuration">Durée fiderana</label>
                      <input id="praiseDuration" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="number" name="praiseDuration" placeholder="7 mn" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="preach">Toriteny</label>
                      <input id="preach" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="preach" placeholder="Pst Stéphanie" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="preachDuration">Durée toriteny</label>
                      <input id="preachDuration" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="number" name="praiseDuration" placeholder="20 mn" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="preachTitle">Lohahevitra toriteny</label>
                      <input id="preachTitle" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="preachTitle" placeholder="Titre toriteny" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="blessing">Tso-drano</label>
                      <input id="blessing" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="blessing" placeholder="Pst Stéphanie" />
                    </div>
                  </div>
                </div>
                <div className="bg-secondary p-8 sm:p-12 rounded-3xl shadow-lg">
                  <h2 className="text-center text-2xl font-semibold mb-8">Informations sur les offrandes</h2>
                  <div className="grid grid-cols-1 xl:grid-cols-3 gap-6">
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="offeringFirst">Fanatitra voalohany (en Ar)</label>
                      <input id="offeringFirst" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="offeringFirst" placeholder="10 000" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="offeringSecond">Fanatitra faharoa (en Ar)</label>
                      <input id="offeringSecond" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="offeringSecond" placeholder="20 000" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="offeringThird">Fanatitra fahatelo (en Ar)</label>
                      <input id="offeringThird" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="offeringThird" placeholder="15 000" />
                    </div>
                  </div>
                  <p className="mt-4">Total : {offeringTotal} Ar</p>
                </div>
                <div className="bg-secondary p-8 sm:p-12 rounded-3xl shadow-lg">
                  <h2 className="text-center text-2xl font-semibold mb-8">Informations sur les témoignages</h2>
                  <div className="grid gap-6">
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="testifier1">Témoin 1</label>
                      <input id="testifier1" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="testifier1" placeholder="Nom et prénom(s) du témoin" />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="testimony1">Témoignage 1</label>
                      <textarea id="testimony1" className="px-4 py-3 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" rows={5} name="testimony1" placeholder="Témoignage"></textarea>
                    </div>
                  </div>
                  <button className="btn bg-sky-800 mt-6" type="button"><IoAddSharp /></button>
                </div>
              </div>
              <button className="btn font-semibold bg-tertiary-200" type="submit">Créer le rapport de cellule</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CellReportingAddPage;
