import React from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <div className="Header">
      <nav className="fixed w-full h-16 bg-secondary shadow-lg flex items-center p-4 lg:ml-80 border-l border-gray-700">
        
      </nav>
    </div>
  );
};

export default Header;
