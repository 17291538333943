import React from "react";
import { NavLink } from "react-router-dom";
import { RiArrowDropRightLine } from "react-icons/ri";
import { classNames } from "../utils";
import logo from '../logo-light.svg';

const Sidebar = ({ currentPage }) => {

  const navigationGeneral = [
    { name: 'Accueil', href: '/home/', current: currentPage === "home" ? true : false },
    { name: 'Mes présences', href: '/presences/', current: currentPage === "presences" ? true : false },
    { name: 'Mon calendrier', href: '/calendar/', current: currentPage === "calendar" ? true : false }
  ]
  
  const navigationCells = [
    { name: 'Mes cellules', href: '/cells/', current: currentPage === "cells" ? true : false },
    { name: 'Rapport cellule', href: '/cells/reportings/', current: currentPage === "cellReportings" ? true : false }
  ]

  return (
    <div className="Sidebar">
      <div className="lg:fixed h-full w-80 bg-secondary shadow-lg hidden lg:flex flex-col justify-between">
        <div className="overflow-y-auto scrollbar-none">
          <div className="border-b border-gray-700 h-16 flex items-center px-6">
            <a className="flex items-center" href="/home">
              <img className="w-16" src={logo} alt="logo" />
              <h2 className="font-semibold">Reporting</h2>
            </a>
          </div>
          <div className="px-6 py-4 space-y-6 text-sm">
            <div className="space-y-3">
              <h3 className="uppercase">Général</h3>
              <ul className="space-y-2">
                {navigationGeneral.map((item) => (
                  <li key={item.name} className="flex">
                    <NavLink className={classNames(item.current ? "bg-tertiary-200" : "hover:bg-tertiary-200", "btn-full pl-6 pr-2 py-2 flex justify-between items-center")} to={item.href}>
                      {item.name}
                      <RiArrowDropRightLine size={36} />
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className="border-t border-gray-700 space-y-3 pt-4">
              <h3 className="uppercase">Cellules</h3>
              <ul className="space-y-2">
                {navigationCells.map((item) => (
                  <li key={item.name} className="flex">
                    <NavLink className={classNames(item.current ? "bg-tertiary-200" : "hover:bg-tertiary-200", "btn-full pl-6 pr-2 py-2 flex justify-between items-center")} to={item.href}>
                      {item.name}
                      <RiArrowDropRightLine size={36} />
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="px-6 py-2">
          <p className="text-center leading-relaxed text-sm">Copyright &copy; 2024 All rights reserved.<br/>Église Évangélique Shine.</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
