import React from "react";

const NotFoundPage = () => {
  return (
    <div className="NotFoundPage">
      <div className="h-screen bg-dark flex flex-col justify-around items-center text-center px-4">
        <div></div>
        <div className="space-y-8">
          <div className="space-y-5">
            <h1 className="text-8xl lg:text-9xl font-semibold">404</h1>
            <h2 className="text-3xl">Désolé !</h2>
            <h3 className="text-lg">La page que vous recherchez n'a pas été trouvée.</h3>
          </div>
          <div>
            <a className="btn font-semibold bg-white hover:bg-gray-200 text-primary" href="/">Back to home</a>
          </div>
        </div>
        <p>Copyright &copy; 2024 All rights reserved. Église Évangélique Shine.</p>
      </div>
    </div>
  )
};

export default NotFoundPage;
