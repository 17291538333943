import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { createTheme } from "react-data-table-component";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { isEmpty } from "../../utils";
import { getCellsRequest } from "../../services/CellService";

const CellPage = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [cells, setCells] = useState([]);
  const [cellsFiltered, setCellsFiltered] = useState([]);

  useEffect(() => {
    const fetchCellData = async () => {
      await getCells();
    };

    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }

      fetchCellData();
      setLoading(false);
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const columns = [
    {
      name: "Mpitarika",
      selector: row => row.leader.alias,
      sortable: true
    },
    {
      name: "Assistant",
      selector: row => isEmpty(row.assistant) ? "-" : row.assistant.alias,
      sortable: true
    },
    {
      name: "Propriétaire",
      selector: row => row.alias,
      sortable: true
    },
    {
      name: "Adresse",
      selector: row => row.address,
      sortable: true
    },
    {
      name: "Jour",
      selector: row => row.scheduledDay,
      sortable: true
    },
    {
      name: "Heure",
      selector: row => row.scheduledHour,
      sortable: true
    },
    {
      name: "",
      cell: row => (
        <div className="flex justify-end w-full">
          <button
            onClick={() => navigate(`/cells/detail/${row.id}/`)}
            className="btn-full py-2.5 bg-tertiary-200"
          >
            Détails
          </button>
        </div>
      ),
      button: "true"
    }
  ];
  
  createTheme('primary', {
    text: {
      primary: "rgb(209 213 219)",
      secondary: "",
    },
    background: {
      default: "",
    },
    divider: {
      default: "rgb(55 65 81)",
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const customStyles = {
    pagination: {
      style: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  }

  const getCells = async () => {
    const response = await getCellsRequest();

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setCells(response.data);
        setCellsFiltered(response.data);
      }
    } else {
      toast.warning("Aucune cellule trouvée");
    }
  };

  const handleSearch = (e) => {
    const newCells = cells.filter((cell) => {
      return cell.name.toLowerCase().includes(e.target.value.toLowerCase()) || cell.category.toLowerCase().includes(e.target.value.toLowerCase());
    });

    setCellsFiltered(newCells);
  }

  return (
    <div className="CellPage">
      <ToastContainer />
      <Header />
      <Sidebar currentPage="cells" />

      <div className="lg:pl-80 pt-14">
        <div className="p-8">
          <div className="w-full h-full space-y-8">
            <div className="space-y-4">
              <h1 className="text-center text-3xl font-semibold">Liste de mes cellules</h1>
              <p className="text-center text-sm">Cliquez sur le bouton détails pour voir les détails</p>
            </div>
            <div>
              <div className="flex flex-col lg:flex-row justify-between">
                <button className="btn h-11 bg-green-700 flex items-center" onClick={() => navigate("/cells/add/")}>Ajouter une cellule</button>
                <input
                  className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary rounded-md shadow-sm outline-none border-gray-700 focus:ring-1 ring-gray-700"
                  type="text"
                  placeholder="Recherche"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
              <DataTable
                theme="primary"
                columns={columns}
                data={cellsFiltered}
                fixedHeader
                pagination
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CellPage;
