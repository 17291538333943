import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { isEmpty } from "../../utils";

const PresencePage = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="PresencePage">
      <ToastContainer />
      <Header />
      <Sidebar currentPage="presence" />

      <div className="pl-80 pt-14">
      <div className="p-8">
          <div className="w-full h-full space-y-8">
            <div className="space-y-4">
              <h1 className="text-center text-3xl font-semibold">Mes présences</h1>
              <p className="text-center text-sm">Réunions auquelles vous avez pris part</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresencePage;
