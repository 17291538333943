import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import DataTable, { createTheme } from "react-data-table-component";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { isEmpty } from "../../utils";

const CellReportingPage = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [cellReportings, setCellReportings] = useState([]);
  const [filteredData, setFilteredData] = useState("");

  const columns = [
    {
      name: "Mpitarika",
      selector: row => row.name,
      sortable: true
    },
    {
      name: "Assistant",
      selector: row => row.code,
      sortable: true
    },
    {
      name: "Propriétaire",
      selector: row => row.code,
      sortable: true
    },
    {
      name: "Adresse",
      selector: row => row.category,
      sortable: true
    },
    {
      name: "Jour",
      selector: row => row.quantity,
      sortable: true
    },
    {
      name: "Heure",
      selector: row => row.quantity,
      sortable: true
    },
    {
      name: "",
      cell: row => (
        <div className="flex justify-end w-full">
          <button
            onClick={() => console.log(row.name)}
            className="btn-full py-2.5 bg-tertiary-200"
          >
            Détails
          </button>
        </div>
      ),
      button: "true"
    }
  ];

  useEffect(() => {
    const fetchCellReportingData = async () => {
      setCellReportings([
        {
          code: "f230fh0g3",
          name: "Bamboo Watch",
          category: "Accessories",
          quantity: 24,
          edit: "<button>Edit me</button>"
        },
        {
          code: "f230fh0g3",
          name: "Bamboo 2",
          category: "Accessories",
          quantity: 24
        },
        {
          code: "f230fh0g3",
          name: "Bamboo 3",
          category: "Accessories",
          quantity: 24
        },
        {
          code: "f230fh0g3",
          name: "Bamboo 4",
          category: "Accessories",
          quantity: 24
        }
      ]);
    };

    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }

      fetchCellReportingData();
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredData(cellReportings)
  }, [cellReportings]);
  
  
  createTheme('primary', {
    text: {
      primary: "rgb(209 213 219)",
      secondary: "",
    },
    background: {
      default: "",
    },
    divider: {
      default: "rgb(55 65 81)",
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const customStyles = {
    pagination: {
      style: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  }

  const handleSearch = (e) => {
    const newCells = cellReportings.filter((cell) => {
      return cell.name.toLowerCase().includes(e.target.value.toLowerCase()) || cell.category.toLowerCase().includes(e.target.value.toLowerCase());
    });

    setFilteredData(newCells);
  }

  return (
    <div className="CellReportingPage">
      <ToastContainer />
      <Header />
      <Sidebar currentPage="cellReportings" />

      <div className="lg:pl-80 pt-14">
        <div className="p-8">
          <div className="w-full h-full space-y-8">
            <div className="space-y-4">
              <h1 className="text-center text-3xl font-semibold">Liste de mes rapports de cellule</h1>
              <p className="text-center text-sm">Cliquez sur le bouton détails pour voir les détails</p>
            </div>
            <div>
              <div className="flex flex-col lg:flex-row justify-between">
                <NavLink className="btn h-11 bg-green-700 flex items-center" to="/cells/reportings/add/">Ajouter un rapport de cellule</NavLink>
                <input
                  className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary rounded-md shadow-sm outline-none border-gray-700 focus:ring-1 ring-gray-700"
                  type="text"
                  placeholder="Recherche"
                  onChange={(e) => handleSearch(e)}
                />
              </div>
              <DataTable
                theme="primary"
                columns={columns}
                data={filteredData}
                fixedHeader
                pagination
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CellReportingPage;
