import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { TailSpin } from 'react-loader-spinner';
import { isEmpty } from "../../utils";
import logo from '../../logo-light.svg';
import { signupUserRequest } from "../../services/AuthenticationService";
import { getChurchesLocationsRequest, getChurchesRequest, getCitiesRequest, getCountriesRequest, getDepartmentsRequest, getSynodesRequest } from "../../services/AdminAppService";

const RegisterPage = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [alias, setAlias] = useState("");
  const [gender, setGender] = useState("male");
  const [country, setCountry] = useState("");
  const [synode, setSynode] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [church, setChurch] = useState("");
  const [churchLocation, setChurchLocation] = useState("");
  const [department, setDepartment] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [countries, setCountries] = useState([]);
  const [synodes, setSynodes] = useState([]);
  const [cities, setCities] = useState([]);
  const [churches, setChurches] = useState([]);
  const [churchesLocations, setChurchesLocations] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const fetchCountryData = async () => {
      await getCountries();
    };
    
    const fetchSynodeData = async () => {
      await getSynodes();
    };

    const fetchCityData = async () => {
      await getCities();
    };

    const fetchChurchData = async () => {
      await getChurches();
    };

    const fetchChurchLocationData = async () => {
      await getChurchesLocations();
    };

    const fetchDepartmentData = async () => {
      await getDepartments();
    };

    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }

      fetchCountryData();
      fetchSynodeData();
      fetchCityData();
      fetchChurchData();
      fetchChurchLocationData();
      fetchDepartmentData();

      setLoading(false);
    }

    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchSynodeData = async () => {
      await getSynodes();
    };

    fetchSynodeData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    const fetchCityData = async () => {
      await getCities();
    };

    fetchCityData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [synode]);

  useEffect(() => {
    const fetchChurchLocationData = async () => {
      await getChurchesLocations();
    };

    fetchChurchLocationData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, church]);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      await getDepartments();
    };

    fetchDepartmentData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [church]);

  const getCountries = async () => {
    const response = await getCountriesRequest();

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setCountries(response.data);
        setCountry(response.data[0].id);
      }
    } else {
      setCountries([]);
      setCountry("");
      toast.warning("Aucun pays trouvé");
    }
  };

  const getSynodes = async () => {
    if (!country) {
      setSynodes([]);
      setSynode("");
      return;
    }

    const response = await getSynodesRequest(country);

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setSynodes(response.data);
        setSynode(response.data[0].id);
      }
    } else {
      setSynodes([]);
      setSynode("");
      toast.warning("Aucun synode trouvé pour ce pays");
    }
  };

  const getCities = async () => {
    if (!synode) {
      setCities([]);
      setCity("");
      return;
    }

    const response = await getCitiesRequest(country, synode);

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setCities(response.data);
        setCity(response.data[0].id);
      }
    } else {
      setCities([]);
      setCity("");
      toast.warning("Aucune ville trouvée pour ce synode");
    }
  };

  const getChurches = async () => {
    const response = await getChurchesRequest();

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setChurches(response.data);
        setChurch(response.data[0].id);
      }
    } else {
      setChurches([]);
      setChurch("");
      toast.warning("Aucune église trouvée");
    }
  };

  const getChurchesLocations = async () => {
    if (!church || !city) {
      setChurchesLocations([]);
      setChurchLocation("");
      return;
    }

    const response = await getChurchesLocationsRequest(country, synode, city, church);

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setChurchesLocations(response.data);
        setChurchLocation(response.data[0].id);
      }
    } else {
      setChurchesLocations([]);
      setChurchLocation("");
      toast.warning("Aucun lieu trouvé pour cette église dans cette ville");
    }
  };

  const getDepartments = async () => {
    if (!church) {
      setDepartments([]);
      setDepartment("");
      return;
    }

    const response = await getDepartmentsRequest(church);

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setDepartments(response.data);
        setDepartment(response.data[0].id);
      }
    } else {
      setDepartments([]);
      setDepartment("");
      toast.warning("Aucun département trouvé pour cette église");
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (password !== passwordConfirmation) {
      return toast.error("Les mots de passe ne sont pas identiques");
    }

    const countryInstance = countries.find((value) => value.id === country);
    const synodeInstance = synodes.find((value) => value.id === synode);
    const cityInstance = cities.find((value) => value.id === city);
    const churchInstance = churches.find((value) => value.id === church);
    const churchLocationInstance = churchesLocations.find((value) => value.id === churchLocation);
    const departmentInstance = departments.find((value) => value.id === department);

    const response = await signupUserRequest(firstName, lastName, alias, gender, countryInstance, synodeInstance, cityInstance, address, churchInstance, churchLocationInstance, departmentInstance, email, phone, password);

    if (response.status === "success") {
      navigate("/login/", {
        state: {
          status: "success",
          message: response.message,
        }
      });
    } else if (response.status === "warning") {
      navigate("/login/", {
        state: {
          status: "warning",
          message: response.message,
        }
      });
    } else {
      setLoading(false);
      toast.error(response.message);
    }
  }

  return (
    <div className="RegisterPage">
      <ToastContainer />

      {loading ? (
        <div className="h-screen flex justify-center items-center p-4">
          <div className="w-full h-full max-w-4xl px-4">
            <div className="h-full bg-secondary space-y-8 p-8 sm:p-16 rounded-3xl shadow-lg border-t-2 border-b-2 border-tertiary-200 flex justify-center items-center">
              <TailSpin
                visible={true}
                height={85}
                width={85}
                color="#ffffff"
                ariaLabel="tail-spin-loading"
                radius={1}
                strokeWidth={4}
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center py-4">
          <div className="w-full max-w-4xl px-4">
            <div className="bg-secondary space-y-8 p-8 sm:p-16 rounded-3xl shadow-lg border-t-2 border-b-2 border-tertiary-200">
              <div className="flex justify-center ml-10">
                <img className="w-28" src={logo} alt="logo" />
              </div>
              <div className="space-y-2">
                <h1 className="text-center text-4xl font-semibold">Création compte</h1>
                <p className="text-center">Veuillez remplir le formulaire</p>
              </div>
              <form className="flex flex-col space-y-6" onSubmit={(e) => handleFormSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="lastName">Nom</label>
                    <input id="lastName" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="lastName" placeholder="Nom de famille"  required value={lastName} onChange={(e) => setLastName(e.target.value)} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="firstName">Prénom(s)</label>
                    <input id="firstName" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="firstName" placeholder="Prénom(s)" required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="alias">Fiantsoana</label>
                    <input id="alias" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="alias" placeholder="Rl Ando" required value={alias} onChange={(e) => setAlias(e.target.value)} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="gender">Genre</label>
                    <select id="gender" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={gender} onChange={(e) => setGender(e.target.value)}>
                      <option value="male">Masculin</option>
                      <option value="female">Féminin</option>
                    </select>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="country">Pays</label>
                    <select id="country" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={country} onChange={(e) => setCountry(e.target.value)}>
                      {countries.map((country) => (
                        <option key={country.id} value={country.id}>{country.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="synode">Synode</label>
                    <select id="synode" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={synode} onChange={(e) => setSynode(e.target.value)}>
                      {synodes.map((synode) => (
                        <option key={synode.id} value={synode.id}>{synode.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="city">Ville</label>
                    <select id="city" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={city} onChange={(e) => setCity(e.target.value)}>
                      {cities.map((city) => (
                        <option key={city.id} value={city.id}>{city.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="address">Adresse</label>
                    <input id="address" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="address" placeholder="Lot Quelque Chose Antanimena" required value={address} onChange={(e) => setAddress(e.target.value)} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="church">Église</label>
                    <select id="church" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={church} onChange={(e) => setChurch(e.target.value)}>
                      {churches.map((church) => (
                        <option key={church.id} value={church.id}>{church.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="churchLocation">Lieu de l'église</label>
                    <select id="churchLocation" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={churchLocation} onChange={(e) => setChurchLocation(e.target.value)}>
                      {churchesLocations.map((churchLocation) => (
                        <option key={churchLocation.id} value={churchLocation.id}>{churchLocation.location} {churchLocation.hour}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="department">Département</label>
                    <select id="department" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={department} onChange={(e) => setDepartment(e.target.value)}>
                      {departments.map((department) => (
                        <option key={department.id} value={department.id}>{department.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="email">E-mail (Optionnel)</label>
                    <input id="email" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="email" name="email" placeholder="Adresse e-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="text font-semibold" htmlFor="phone">Téléphone</label>
                    <input id="phone" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="tel" name="phone" placeholder="Numéro de téléphone" required value={phone} onChange={(e) => setPhone(e.target.value)} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="password">Mot de passe</label>
                    <input id="password" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="password" name="password" placeholder="Mot de passe" required value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="passwordConfirmation">Confirmation mot de passe</label>
                    <input id="passwordConfirmation" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="password" name="passwordConfirmation" placeholder="Ressaisir le mot de passe" required value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
                  </div>
                </div>
                <button className="btn font-semibold bg-tertiary-200" type="submit">Créer un compte</button>
                <p className="text-sm text-center">Déjà inscrit ? <NavLink className="text-sky-500 hover:opacity-75 transition ease-linear duration-100" to="/login/">Se connecter</NavLink></p>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisterPage;
