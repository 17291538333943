import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { TailSpin } from 'react-loader-spinner';
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { isEmpty } from "../../utils";
import { getCellRequest } from "../../services/CellService";

const CellDetailPage = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const {cellId} = useParams();

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [alias, setAlias] = useState("");
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [leader, setLeader] = useState("");
  const [assistant, setAssistant] = useState("");
  const [yearStart, setYearStart] = useState("");
  const [creator, setCreator] = useState("");
  const [country, setCountry] = useState("");
  const [synode, setSynode] = useState("");
  const [city, setCity] = useState("");
  const [church, setChurch] = useState("");
  const [churchLocation, setChurchLocation] = useState("");
  const [borough, setBorough] = useState("");
  const [address, setAddress] = useState("");
  const [scheduledDay, setScheduledDay] = useState("");
  const [scheduledHour, setScheduledHour] = useState("");

  useEffect(() => {
    const fetchCellData = async () => {
      await getCell();
    };

    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }

      fetchCellData();
      setLoading(false);
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCell = async () => {
    const response = await getCellRequest(cellId);

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setLastName(response.data.lastName);
        setFirstName(response.data.firstName);
        setAlias(response.data.alias);
        setGender(response.data.gender);
        setPhone(response.data.phone);
        setLeader(response.data.leader);
        setAssistant(response.data.assistant);
        setYearStart(response.data.yearStart);
        setCreator(response.data.creator);
        setCountry(response.data.country);
        setSynode(response.data.synode);
        setCity(response.data.city);
        setChurch(response.data.church);
        setChurchLocation(response.data.churchLocation);
        setBorough(response.data.borough);
        setAddress(response.data.address);
        setScheduledDay(response.data.scheduledDay);
        setScheduledHour(response.data.scheduledHour);
      }
    } else {
      toast.error(response.message);
      navigate("/cells/", {
        state: {
          status: "error",
          message: response.message,
        }
      });
    }
  };

  const handleDeleteCell = () => {
    // TODO Show modal to ask if the user really wants to delete the cell
  }

  return (
    <div className="CellAddPage">
      <ToastContainer />
      <Header />
      <Sidebar currentPage="cells" />

      <div className="lg:pl-80 pt-14">
        <div className="p-8">
          <div className="w-full h-full space-y-8">
            <div className="space-y-4">
              <h1 className="text-center text-3xl font-semibold">Détails cellule</h1>
              <p className="text-center text-sm">Vous avez aussi la possibilité de modifier ou de supprimer la cellule</p>
            </div>
            <div className="flex flex-col space-y-10 sm:mx-16">
              {loading ? (
                <div className="flex justify-center">
                  <TailSpin
                    visible={true}
                    height={85}
                    width={85}
                    color="#ffffff"
                    ariaLabel="tail-spin-loading"
                    radius={1}
                    strokeWidth={4}
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <>
                  <div className="space-y-12">
                    <div className="bg-secondary p-8 sm:p-12 rounded-3xl shadow-lg">
                      <h2 className="text-center text-2xl font-semibold mb-8">Informations sur le propriétaire</h2>
                      <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="lastName">Nom</label>
                          <p className="text-gray-400">{lastName}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="firstName">Prénom(s)</label>
                          <p className="text-gray-400">{firstName}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="alias">Fiantsoana</label>
                          <p className="text-gray-400">{alias}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="gender">Genre</label>
                          <p className="text-gray-400">{gender}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="text font-semibold" htmlFor="phone">Téléphone</label>
                          <p className="text-gray-400">{phone}</p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-secondary p-8 sm:p-12 rounded-3xl shadow-lg">
                      <h2 className="text-center text-2xl font-semibold mb-8">Informations sur la cellule de prière</h2>
                      <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="leader">Mpitarika</label>
                          <p className="text-gray-400">{leader.lastName} {leader.firstName} ({leader.alias})</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="assistant">Assistant</label>
                          <p className="text-gray-400">{isEmpty(assistant) ? "Aucun" : `${assistant.lastName} ${assistant.firstName} (${assistant.alias})`}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="yearStart">Taona nananganana an'ilay cellule</label>
                          <p className="text-gray-400">{yearStart}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="creator">Iza no nanangana azy ?</label>
                          <p className="text-gray-400">{creator.lastName} {creator.firstName} ({creator.alias})</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="country">Pays</label>
                          <p className="text-gray-400">{country}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="synode">Synode</label>
                          <p className="text-gray-400">{synode}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="city">Ville</label>
                          <p className="text-gray-400">{city}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="church">Église</label>
                          <p className="text-gray-400">{church}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="churchLocation">Lieu de l'église</label>
                          <p className="text-gray-400">{churchLocation}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="borough">Arrondissement</label>
                          <p className="text-gray-400">{borough}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="address">Adresse</label>
                          <p className="text-gray-400">{address}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="scheduledDay">Jour prévu</label>
                          <p className="text-gray-400">{scheduledDay}</p>
                        </div>
                        <div className="flex flex-col space-y-2">
                          <label className="font-semibold" htmlFor="scheduledHour">Heure prévue</label>
                          <p className="text-gray-400">{scheduledHour}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <button className="btn font-semibold bg-yellow-600" onClick={() => navigate(`/cells/edit/${cellId}/`)}>Modifier la cellule</button>
                    <button className="btn font-semibold bg-red-600" onClick={() => handleDeleteCell()}>Supprimer la cellule</button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CellDetailPage;
