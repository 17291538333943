import axios from "axios";
import camelize from "camelize";
import { getApiUri, getHeaders } from "./NetworkService";

export const getCellsRequest = async () => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/cells/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération des cellules";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Erreur lors de la récupération des cellules";
    });

  return responseData;
};

export const getCellRequest = async ( id ) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "data": null,
  };

  await axios({ method: "GET", url: `${uri}/api/v1/cells/${id}/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Erreur lors de la récupération de la cellule";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Erreur lors de la récupération de la cellule";
    });

  return responseData;
};

export const createCellRequest = async ( firstName, lastName, alias, gender, phone, leaderId, assistantId, yearStart, creatorId, countryName, synodeName, cityName, churchName, churchLocationName, borough, address, scheduledDay, scheduledHour ) => {
  const uri = getApiUri();

  const data = {
    first_name: firstName,
    last_name: lastName,
    alias: alias,
    gender: gender,
    phone: phone,
    leader_id: leaderId,
    assistant_id: assistantId,
    year_start: yearStart,
    creator_id: creatorId,
    country: countryName,
    synode: synodeName,
    city: cityName,
    church: churchName,
    church_location: churchLocationName,
    borough: borough,
    address: address,
    scheduled_day: scheduledDay,
    scheduled_hour: scheduledHour,
  };
  
  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "POST", url: `${uri}/api/v1/cells/`, data: data, headers: getHeaders() })
    .then((response) => {
      if (response.status === 201) {
        responseData.status = "success";
        responseData.message = "Cellule créée avec succès";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Création impossible";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Création impossible";
    });

  return responseData;
};

export const updateCellRequest = async ( id, firstName, lastName, alias, gender, phone, leader_id, assistant_id, yearStart, creator_id, countryName, synodeName, cityName, churchName, churchLocationName, borough, address, scheduledDay, scheduledHour ) => {
  const uri = getApiUri();

  const data = {
    first_name: firstName,
    last_name: lastName,
    alias: alias,
    gender: gender,
    phone: phone,
    leader_id: leader_id,
    assistant_id: assistant_id,
    year_start: yearStart,
    creator_id: creator_id,
    country: countryName,
    synode: synodeName,
    city: cityName,
    church: churchName,
    church_location: churchLocationName,
    borough: borough,
    address: address,
    scheduled_day: scheduledDay,
    scheduled_hour: scheduledHour,
  };

  let responseData = {
    "status": null,
    "message": null,
    "data": null,
  };

  await axios({ method: "PATCH", url: `${uri}/api/v1/cells/${id}/`, data: data, headers: getHeaders() })
    .then((response) => {
      if (response.status === 200) {
        responseData.status = "success";
        responseData.message = "Cellule modifiée avec succès";
        responseData.data = camelize(response.data);
      } else {
        responseData.status = "error";
        responseData.message = "Modification impossible";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Modification impossible";
    });

  return responseData;
};

export const deleteCellRequest = async ( id ) => {
  const uri = getApiUri();

  let responseData = {
    "status": null,
    "message": null,
  };

  await axios({ method: "DELETE", url: `${uri}/api/v1/cells/${id}/`, headers: getHeaders() })
    .then((response) => {
      if (response.status === 204) {
        responseData.status = "success";
        responseData.message = "Cellule supprimée avec succès";
      } else {
        responseData.status = "error";
        responseData.message = "Suppression impossible";
      }
    }).catch((error) => {
      responseData.status = "error";
      responseData.message = "Suppression impossible";
    });

  return responseData;
};
