import { getToken } from "./AuthenticationService";

export const getApiUri = () => {
  return process.env.REACT_APP_API_URL;
};

export const getAdminApiUri = () => {
  return process.env.REACT_APP_ADMIN_API_URL;
};

export const getHeaders = () => {
  return {
    'Authorization': getToken()
  }
}
