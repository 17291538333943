import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { TailSpin } from 'react-loader-spinner';
import { isEmpty } from "../../utils";
import logo from '../../logo-light.svg';
import { loginUserRequest, storeToken } from "../../services/AuthenticationService";

const LoginPage = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await loginUserRequest(login, password);

    if (response.status === "success") {
      storeToken(response.data.access, response.data.refresh);
      navigate("/home/", {
        state: {
          status: "success",
          message: response.message,
        }
      });
    } else {
      setLoading(false);
      toast.error(response.message);
    }
  }

  return (
    <div className="LoginPage">
      <ToastContainer />

      <div className="h-screen flex justify-center items-center py-4">
        <div className="w-full max-w-xl px-4">
          <div className="bg-secondary space-y-8 p-8 sm:p-16 rounded-3xl shadow-lg border-t-2 border-b-2 border-tertiary-200">
            {loading ? (
              <div className="flex justify-center items-center h-80">
                <TailSpin
                  visible={true}
                  height={85}
                  width={85}
                  color="#ffffff"
                  ariaLabel="tail-spin-loading"
                  radius={1}
                  strokeWidth={4}
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <>
                <div className="flex justify-center ml-10">
                  <img className="w-28" src={logo} alt="logo" />
                </div>
                <div className="space-y-2">
                  <h1 className="text-center text-4xl font-semibold">Bienvenue</h1>
                  <p className="text-center">Veuillez vous connecter</p>
                </div>
                <form className="flex flex-col space-y-6" onSubmit={(e) => handleFormSubmit(e)}>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="email">E-mail ou Téléphone</label>
                    <input id="email" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="text" placeholder="Adresse e-mail ou numéro téléphone" required value={login} onChange={(e) => setLogin(e.target.value)} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-semibold" htmlFor="password">Mot de passe</label>
                    <input id="password" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="password" name="password" placeholder="Mot de passe" required value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <div className="flex justify-between">
                    <NavLink className="text-sm text-tertiary-100 hover:opacity-75 transition ease-linear duration-100" to="">Mot de passe oublié ?</NavLink>
                  </div>
                  <button className="btn font-semibold bg-tertiary-200" type="submit">Se connecter</button>
                  <p className="text-sm text-center">Pas encore inscrit ? <NavLink className="text-sky-500 hover:opacity-75 transition ease-linear duration-100" to="/signup/">Créer un compte</NavLink></p>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
