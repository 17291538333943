import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { isEmpty } from "../../utils";
import { getChurchesLocationsRequest, getChurchesRequest, getCitiesRequest, getCountriesRequest, getSynodesRequest } from "../../services/AdminAppService";
import { getUsersRequest } from "../../services/UserService";
import { createCellRequest } from "../../services/CellService";

const CellAddPage = () => {
  const initialRender = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [alias, setAlias] = useState("");
  const [gender, setGender] = useState("male");
  const [phone, setPhone] = useState("");
  const [leader, setLeader] = useState("");
  const [assistant, setAssistant] = useState("");
  const [yearStart, setYearStart] = useState("");
  const [creator, setCreator] = useState("");
  const [country, setCountry] = useState("");
  const [synode, setSynode] = useState("");
  const [city, setCity] = useState("");
  const [church, setChurch] = useState("");
  const [churchLocation, setChurchLocation] = useState("");
  const [borough, setBorough] = useState("Firaisana voalohany");
  const [address, setAddress] = useState("");
  const [scheduledDay, setScheduledDay] = useState("monday");
  const [scheduledHour, setScheduledHour] = useState("");

  const [countries, setCountries] = useState([]);
  const [synodes, setSynodes] = useState([]);
  const [cities, setCities] = useState([]);
  const [churches, setChurches] = useState([]);
  const [churchesLocations, setChurchesLocations] = useState([]);
  const [users, setUsers] = useState([]);
  const [leaders, setLeaders] = useState([]);  
  const [assistants, setAssistants] = useState([]);  
  const [creators, setCreators] = useState([]);  

  useEffect(() => {
    const fetchCountryData = async () => {
      await getCountries();
    };
    
    const fetchSynodeData = async () => {
      await getSynodes();
    };

    const fetchCityData = async () => {
      await getCities();
    };

    const fetchChurchData = async () => {
      await getChurches();
    };

    const fetchChurchLocationData = async () => {
      await getChurchesLocations();
    };

    const fetchUserData = async () => {
      await getUsers();
    }

    if (initialRender.current) {
      if (location.state) {
        if (!isEmpty(location.state.message)) {
          if (location.state.status === "success") {
            toast.success(location.state.message);
          } else if (location.state.status === "warning") {
            toast.warning(location.state.message);
          } else {
            toast.error(location.state.message);
          }
          window.history.replaceState({}, document.title);
        }
      }

      fetchCountryData();
      fetchSynodeData();
      fetchCityData();
      fetchChurchData();
      fetchChurchLocationData();
      fetchUserData();

      setLoading(false);
    }
    return () => {
      initialRender.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchSynodeData = async () => {
      await getSynodes();
    };

    fetchSynodeData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    const fetchCityData = async () => {
      await getCities();
    };

    fetchCityData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [synode]);

  useEffect(() => {
    const fetchChurchLocationData = async () => {
      await getChurchesLocations();
    };

    fetchChurchLocationData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, church]);

  const getCountries = async () => {
    const response = await getCountriesRequest();

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setCountries(response.data);
        setCountry(response.data[0].id);
      }
    } else {
      setCountries([]);
      setCountry("");
      toast.warning("Aucun pays trouvé");
    }
  };

  const getSynodes = async () => {
    if (!country) {
      setSynodes([]);
      setSynode("");
      return;
    }

    const response = await getSynodesRequest(country);

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setSynodes(response.data);
        setSynode(response.data[0].id);
      }
    } else {
      setSynodes([]);
      setSynode("");
      toast.warning("Aucun synode trouvé pour ce pays");
    }
  };

  const getCities = async () => {
    if (!synode) {
      setCities([]);
      setCity("");
      return;
    }

    const response = await getCitiesRequest(country, synode);

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setCities(response.data);
        setCity(response.data[0].id);
      }
    } else {
      setCities([]);
      setCity("");
      toast.warning("Aucune ville trouvée pour ce synode");
    }
  };

  const getChurches = async () => {
    const response = await getChurchesRequest();

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setChurches(response.data);
        setChurch(response.data[0].id);
      }
    } else {
      setChurches([]);
      setChurch("");
      toast.warning("Aucune église trouvée");
    }
  };

  const getChurchesLocations = async () => {
    if (!church || !city) {
      setChurchesLocations([]);
      setChurchLocation("");
      return;
    }

    const response = await getChurchesLocationsRequest(country, synode, city, church);

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setChurchesLocations(response.data);
        setChurchLocation(response.data[0].id);
      }
    } else {
      setChurchesLocations([]);
      setChurchLocation("");
      toast.warning("Aucun lieu trouvé pour cette église dans cette ville");
    }
  };

  const getUsers = async () => {
    const response = await getUsersRequest();

    if (response.status === "success") {
      if (!isEmpty(response.data)) {
        setUsers(response.data);
        setLeaders(response.data);
        setAssistants(response.data.slice(1));
        setCreators(response.data);

        setLeader(response.data[0].id);
        setCreator(response.data[0].id);
      }
    } else {
      setChurches([]);
      setChurch("");
      toast.warning("Aucun utilisateur trouvé");
    }
  };

  const handleChangeLeader = (e) => {
    setLeader(e.target.value);

    let newAssistants = [...users];
    newAssistants = newAssistants.filter((user) => user.id !== e.target.value)
    setAssistants(newAssistants);
  };

  const handleChangeAssistant = (e) => {
    setAssistant(e.target.value);
    
    let newLeaders = [...users];
    newLeaders = newLeaders.filter((user) => user.id !== e.target.value)
    setLeaders(newLeaders);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const countryName = countries.find((value) => value.id === country).name;
    const synodeName = synodes.find((value) => value.id === synode).name;
    const cityName = cities.find((value) => value.id === city).name;
    const churchName = churches.find((value) => value.id === church).name;
    const churchLocationInstance = churchesLocations.find((value) => value.id === churchLocation);
    const churchLocationName = isEmpty(churchLocationInstance.hour) ? churchLocationInstance.location : churchLocationInstance.location + " " + churchLocationInstance.hour;

    const response = await createCellRequest(firstName, lastName, alias, gender, phone, leader, assistant, yearStart, creator, countryName, synodeName, cityName, churchName, churchLocationName, borough, address, scheduledDay, scheduledHour);

    if (response.status === "success") {
      navigate("/cells/", {
        state: {
          status: "success",
          message: response.message,
        }
      });
    } else {
      setLoading(false);
      toast.error(response.message);
    }
  }

  return (
    <div className="CellAddPage">
      <ToastContainer />
      <Header />
      <Sidebar currentPage="cells" />

      <div className="lg:pl-80 pt-14">
        <div className="p-8">
          <div className="w-full h-full space-y-8">
            <div className="space-y-4">
              <h1 className="text-center text-3xl font-semibold">Ajouter une cellule</h1>
              <p className="text-center text-sm">En remplissant les informations suivantes</p>
            </div>
            <form className="flex flex-col space-y-10 sm:mx-16" onSubmit={(e) => handleFormSubmit(e)}>
              <div className="space-y-12">
                <div className="bg-secondary p-8 sm:p-12 rounded-3xl shadow-lg">
                  <h2 className="text-center text-2xl font-semibold mb-8">Informations sur le propriétaire</h2>
                  <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="lastName">Nom</label>
                      <input id="lastName" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="lastName" placeholder="Nom de famille du propriétaire" required value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="firstName">Prénom(s)</label>
                      <input id="firstName" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="firstName" placeholder="Prénom(s) du propriétaire" required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="alias">Fiantsoana</label>
                      <input id="alias" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="alias" placeholder="Rl Ando" required value={alias} onChange={(e) => setAlias(e.target.value)} />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="gender">Genre</label>
                      <select id="gender" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={gender} onChange={(e) => setGender(e.target.value)}>
                        <option value="male">Masculin</option>
                        <option value="female">Féminin</option>
                      </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="text font-semibold" htmlFor="phone">Téléphone</label>
                      <input id="phone" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="tel" name="phone" placeholder="Numéro de téléphone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </div>
                  </div>
                </div>
                <div className="bg-secondary p-8 sm:p-12 rounded-3xl shadow-lg">
                  <h2 className="text-center text-2xl font-semibold mb-8">Informations sur la cellule de prière</h2>
                  <div className="grid grid-cols-1 xl:grid-cols-2 gap-6">
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="leader">Mpitarika</label>
                      <select id="leader" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={leader} onChange={(e) => handleChangeLeader(e)}>
                        {leaders.map((leader) => (
                          <option key={leader.id} value={leader.id}>{leader.alias} ({leader.lastName} {leader.firstName})</option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="assistant">Assistant</label>
                      <select id="assistant" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" value={assistant} onChange={(e) => handleChangeAssistant(e)}>
                        <option value="">-</option>
                        {assistants.map((assistant) => (
                          <option key={assistant.id} value={assistant.id}>{assistant.alias} ({assistant.lastName} {assistant.firstName})</option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="yearStart">Taona nananganana an'ilay cellule</label>
                      <input id="yearStart" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="number" name="yearStart" placeholder="2022" required value={yearStart} onChange={(e) => setYearStart(e.target.value)} />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="creator">Iza no nanangana azy ?</label>
                      <select id="creator" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={creator} onChange={(e) => setCreator(e.target.value)}>
                        {creators.map((creator) => (
                          <option key={creator.id} value={creator.id}>{creator.alias} ({creator.lastName} {creator.firstName})</option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="country">Pays</label>
                      <select id="country" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={country} onChange={(e) => setCountry(e.target.value)}>
                        {countries.map((country) => (
                          <option key={country.id} value={country.id}>{country.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="synode">Synode</label>
                      <select id="synode" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={synode} onChange={(e) => setSynode(e.target.value)}>
                        {synodes.map((synode) => (
                          <option key={synode.id} value={synode.id}>{synode.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="city">Ville</label>
                      <select id="city" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={city} onChange={(e) => setCity(e.target.value)}>
                        {cities.map((city) => (
                          <option key={city.id} value={city.id}>{city.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="church">Église</label>
                      <select id="church" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={church} onChange={(e) => setChurch(e.target.value)}>
                        {churches.map((church) => (
                          <option key={church.id} value={church.id}>{church.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="churchLocation">Lieu de l'église</label>
                      <select id="churchLocation" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={churchLocation} onChange={(e) => setChurchLocation(e.target.value)}>
                        {churchesLocations.map((churchLocation) => (
                          <option key={churchLocation.id} value={churchLocation.id}>{churchLocation.location} {churchLocation.hour}</option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="borough">Arrondissement</label>
                      <select id="borough" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={borough} onChange={(e) => setBorough(e.target.value)}>
                        <option value="Firaisana voalohany">Firaisana voalohany</option>
                        <option value="Firaisana faharoa">Firaisana faharoa</option>
                        <option value="Firaisana fahatelo">Firaisana fahatelo</option>
                        <option value="Firaisana fahaefatra">Firaisana fahaefatra</option>
                        <option value="Firaisana fahadimy">Firaisana fahadimy</option>
                        <option value="Firaisana fahaenina">Firaisana fahaenina</option>
                        <option value="Firaisana fahafito">Firaisana fahafito</option>
                      </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="address">Adresse</label>
                      <input id="address" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="address" placeholder="Adresse de la cellule" required value={address} onChange={(e) => setAddress(e.target.value)} />
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="scheduledDay">Jour prévu</label>
                      <select id="scheduledDay" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" required value={scheduledDay} onChange={(e) => setScheduledDay(e.target.value)}>
                        <option value="monday">Lundi</option>
                        <option value="tuesday">Mardi</option>
                        <option value="wednesday">Mercredi</option>
                        <option value="thursday">Jeudi</option>
                        <option value="friday">Vendredi</option>
                        <option value="saturday">Samedi</option>
                      </select>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <label className="font-semibold" htmlFor="scheduledHour">Heure prévue</label>
                      <input id="scheduledHour" className="px-4 py-3 h-11 border text-sm text-gray-200 bg-primary border-none rounded-md shadow-sm focus:outline-none focus:border-gray-700 focus:ring-1 focus:ring-gray-700" type="text" name="scheduledHour" placeholder="15h30" required value={scheduledHour} onChange={(e) => setScheduledHour(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <button className="btn font-semibold bg-tertiary-200" type="submit">Ajouter une cellule</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CellAddPage;
